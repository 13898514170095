

import store from "@/store";
import wss from "@/service/WebSocket";

const pubKey = 'BPIUb5jGAsgcBzf8BedzddlQsxTNN8T8w26jlYSUN9JDPUMaT1I1la51rsvrch2K7XP2GLeFVX5-trN_UqLTl8g'

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

export default {
    name: "CheckSubscribe",
    data() {
        return {

            swRegistration: null,

            showSubscribeBtn: false,
            showPushesDenied: false,
            showPutAppToHomeScreenBanner: false,

        }
    },
    methods: {

        async enablePush() {



            const subscription = await this.swRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(pubKey)
            });

            if (subscription) {
                await this.setSubscription(subscription)
                this.$xapi.xnotify('Push уведомления разрешены')
            }


        },
        async setSubscription(subscription) {

            const key = subscription.getKey('p256dh');
            const token = subscription.getKey('auth');
            const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

            const subscription_data = {
                endpoint: subscription.endpoint,
                publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                contentEncoding,
            }

            store.commit('setAuthToken', subscription_data.authToken)

            await this.$xapi.post(
                '/subscriptions',
                {
                    action: 'saveSubscription',
                    data: subscription_data,
                },
            );

            this.showSubscribeBtn = false

            let interval = setInterval(async () => {

                if (this.$store.state.wssConnected) {

                    const response = await wss.request('sendPushSubscription', {auth_token: subscription_data.authToken})
                    if (response?.result === 'success') {
                        console.log(response?.data)
                        clearInterval(interval);
                    }

                }


            }, 1000)

        }

    },
    async created() {

        const broadcast = new BroadcastChannel('fromSW')
        broadcast.onmessage = message => {

            console.log('message from sw', message?.data?.action)

            switch (message?.data?.action) {

                case 'reSubscribe':

                    this.setSubscription(message?.data?.data?.subscription)

                    break;

            }

        }

        window.addEventListener('blur', () => {

            navigator.serviceWorker.controller.postMessage({
                action: 'blur'
            })

            //window.focus()

        })

        window.addEventListener('focus', async () => {

            navigator.serviceWorker.controller.postMessage({
                action: 'focus'
            })

        })

        if (!('serviceWorker' in navigator)) {
            return;
        }

        if (!('PushManager' in window)) {
            this.showPutAppToHomeScreenBanner = true
            return;
        }

        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            return;
        }

        this.swRegistration = await navigator.serviceWorker.ready;

        let subscription = await this.swRegistration.pushManager.getSubscription();

        if (subscription)
            await this.setSubscription(subscription)


        switch (Notification.permission) {

            case "denied":
                this.showPushesDenied = true
                break;

            case "default":

                if (!subscription)
                    this.showSubscribeBtn = true

                break;

            case 'granted':

                if (!subscription) {

                    const subscription = await this.swRegistration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(pubKey)
                    });

                    if (subscription) {
                        await this.setSubscription(subscription)
                        console.log('Обновлен токен для push notifications')
                    }

                }

                break;

        }

        /*setInterval(() => {

            navigator.serviceWorker.controller.postMessage({
                action: 'showTestPush'
            })

        }, 5000)*/


    },

}
