class FileStreamer {

    private file: Blob;
    private offset: number = 0;

    constructor(file: Blob) {
        this.file = file
    }

    public resetPosition = () => {
        this.offset = 0
    }

    public readNextChunk = async (size: number = 65535) => {

        const blob = this.file.slice(this.offset, this.offset + size);
        const buffer = await this.readFile(blob);

        this.offset += blob.size;

        return buffer;

    }

    public isEndOfFile() {
        return this.offset >= this.getFileSize();
    }

    private getFileSize() {
        return this.file.size;
    }

    private async eventPromise(target, eventName): Promise<any> {
        return new Promise((resolve) => {
            const handleEvent = (event) => {
                resolve(event);
            };
            target.addEventListener(eventName, handleEvent);
        });
    }

    private async readFile(blob) {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(blob);
        const event = await this.eventPromise(fileReader, 'loadend');
        const target = event.target;
        if (target.error) {
            throw target.error;
        }
        return target.result;
    }

}

export default FileStreamer