import {createStore} from 'vuex'
import router from "./router";
import {Xapi} from "@xnpmpackages/xcomponents";
import wss from "@/service/WebSocket";
import * as idb from 'idb-keyval'

const timers = {}

// Create a new store instance.
const store = createStore({
    state() {
        return {

            userInfo: null,
            userPermissions: null,

            applicationVersion: null,
            publicParams: null,

            debugModeOn: false,
            chatList: null,

            users: null,
            wssConnected: null,

            auth_token: null,

            logs: [],
            isIos: false,

            nowTypingUsers: {},
        }

    },
    mutations: {
        addUserTyping(state: any, data) {

            if (!state.nowTypingUsers?.[data?.chat_id])
                state.nowTypingUsers[data?.chat_id] = {}

            if (!state.nowTypingUsers[data?.chat_id][data?.user_typing])
                state.nowTypingUsers[data?.chat_id][data?.user_typing] = 0

            state.nowTypingUsers[data?.chat_id][data?.user_typing]++

            timers[data?.chat_id + '_' + data?.user_typing] = setTimeout(() => {

                if (!state.nowTypingUsers[data?.chat_id])
                    return;

                if (--state.nowTypingUsers[data?.chat_id][data?.user_typing] <= 0)
                    delete state.nowTypingUsers[data?.chat_id][data?.user_typing]

                if (state.nowTypingUsers[data?.chat_id] && typeof state.nowTypingUsers[data?.chat_id] === 'object') {
                    if (!Object.keys(state.nowTypingUsers[data?.chat_id]).length)
                        delete state.nowTypingUsers[data?.chat_id]
                }


            }, 5000)

        },
        removeUserTyping(state: any, data) {

            if (state.nowTypingUsers[data.chat_id]?.[data.user_id])
                delete state.nowTypingUsers[data.chat_id]?.[data.user_id]

            if (state.nowTypingUsers[data?.chat_id] && typeof state.nowTypingUsers[data?.chat_id] === 'object') {
                if (!Object.keys(state.nowTypingUsers[data?.chat_id]).length)
                    delete state.nowTypingUsers[data?.chat_id]
            }


            clearTimeout(timers[data?.chat_id + '_' + data?.user_id])

        },
        setUserInfo(state: any, $userInfo) {
            localStorage.setItem('userInfo', JSON.stringify($userInfo));
            state.userInfo = $userInfo;
        },
        setUserPermissions(state, userPermissions) {
            localStorage.setItem('userPermissions', JSON.stringify(userPermissions));
            state.userPermissions = userPermissions;
        },
        setApplicationVersion(state: typeof store.state, $data) {
            state.applicationVersion = $data;
        },
        setPublicParams(state, $publicParams) {
            state.publicParams = $publicParams;
        },
        setDebugModeOn(state, $data) {
            state.debugModeOn = $data;
        },
        setChatList(state, $data) {
            state.chatList = $data;
            idb.set('chatList', $data)
        },
        setUsers(state, $data) {
            state.users = $data;
        },
        setIsIos(state, $data) {
            state.isIos = $data;
        },
        setWssConnected(state, $data) {
            state.wssConnected = $data;
        },
        setAuthToken(state, $data) {
            state.auth_token = $data;
        },
        addLog(state, $data) {
            state.logs.push($data);
            console.log($data);
        },

    },
    actions: {

        async logoff() {

            try {

                this.commit('setUserInfo', null);
                //this.commit('setSessionId', null);

                Xapi.showBlockModal('Выход...');

                await Xapi.post(
                    '/enter',
                    {
                        action: 'logout',
                    }
                );
                this.commit('setMenuItems', null);
                this.commit('setCurrentMenuItems', null);
                Xapi.hideBlockModal();
                await router.push('/login');

            } finally {
                Xapi.hideBlockModal();
            }

        },

        async getApplicationVersion() {

            if (process.env.NODE_ENV === 'development')
                return;

            const response = await Xapi.post(
                '/common-actions',
                {
                    action: 'getApplicationVersion',
                },
            );

            this.commit('setApplicationVersion', response.data.version);

            return response;

        },

        async getPublicParams() {

            const response = await Xapi.post('/common-actions', {
                action: 'getPublicParams',
            });

            this.commit('setPublicParams', response.data.publicParams);

            return response;

        },



    },

})

if (!store.state.userInfo /*|| !store.state.session_id*/) {
    const retrievedObject = localStorage.getItem('userInfo');
    try {
        const userInfo = JSON.parse(retrievedObject);
        store.commit('setUserInfo', userInfo);
        store.commit('setUserPermissions', JSON.parse(localStorage.getItem('userPermissions')));
        // eslint-disable-next-line no-empty
    } catch (e) {

    }

}

// Загрузка последних сообщений из local storage

const loadChatList = async () => {

    try {
        const chatList = await idb.get('chatList')
        if (chatList)
            store.commit('setChatList', chatList)
    } catch (e) {
        store.commit('addLog', e.message)
    }

}

loadChatList();

setInterval(() => {
    const now_state = (wss.socket && wss.socket.readyState === 1)
    const was_state = store.state.wssConnected

    if (now_state !== was_state)
        store.commit('setWssConnected', (wss.socket && wss.socket.readyState === 1))

}, 100);

const iOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

if (iOS())
    store.commit('setIsIos', true)

export default store;