<template>
    <div class="layout-footer" style="justify-content: space-between;">
        <!--        <img alt="Logo" :src="footerImage()" height="20" class="mr-2" />-->
        <span><!--{{ this.$store.state.publicParams }}--></span>
        <span class="font-medium ml-2">
            <span v-if="$env.NODE_ENV === 'development'">Процесс разработки: отладочная версия</span>
            <span v-else>{{ this.$store.state.applicationVersion }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
    methods: {
        footerImage() {
            return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
        }
    },
    computed: {
        darkTheme() {
            return this.$appState.darkTheme;
        }
    }
}
</script>