<template>

    <div class="app">
        <CheckSubscribe/>

        <router-view v-slot="{ Component }">
            <keep-alive>

                <component :is="Component"/>

            </keep-alive>
        </router-view>

    </div>

</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import MenuService from "./service/MenuService";
import {AppEventBus} from "@xnpmpackages/xcomponents";
import CheckSubscribe from "@/components/CheckSubscribe";
import wss from "@/service/WebSocket";

export default {

    emits: ['change-theme'],
    provide() {
        return {}
    },
    created() {

        this.menuService = new MenuService();
        window.addEventListener('keydown', (e) => {
            if (e.altKey && e.ctrlKey && e.key === 'd') {
                this.$store.commit('setDebugModeOn', !this.$store.state.debugModeOn)
            }
        });

    },
    async mounted() {

        this.menuService.getMenuItems().then(data => {
            this.menu = data;
        });

        AppEventBus.on('updateMenu', data => {
            this.menu = data;
        });

        /* IOS fix */
        addEventListener('scroll', () => {
            window.scroll({top: 1, behavior: 'auto'})
        })
        /*setInterval(() => {
            window.scroll({top: 1, behavior: 'auto'})
        }, 100)*/
        addEventListener('visibilitychange', () => {

            wss.appIsActive = document.visibilityState === 'visible'

            if (wss.appIsActive) {
                console.log('Окно стало активным, открываем WS соединение')
                wss.connect()
            } else {
                console.log('Окно стало неактивным, закрываем WS соединение')
                wss.disconnect()
            }


        })

    },
    data() {
        return {
            isLoggedIn: false,
            modalInfoVisible: true,
            modalInfoContent: '',

            menuService: null,

            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [],
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                } else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {

        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    beforeCreate() {
        //this.$store.dispatch('getServerLimits');
        this.$store.dispatch('getPublicParams');
        this.$store.dispatch('getApplicationVersion');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
        CheckSubscribe,
    }
}
</script>

<style lang="scss">

@import './App.scss';

.p-password.w-full > input.p-password-input {
    width: 100% !important;
}

.app {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    min-height: 100% !important;

    @media screen and (min-width: 1024px) {

        flex-direction: row;

    }

}

</style>

