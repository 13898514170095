

import {AppEventBus, BlockNotify, Notifies} from "@xnpmpackages/xcomponents/dist/index";
import ProgressBar from "primevue/progressbar";
import wss from "@/service/WebSocket";

export default {
    themeChangeListener: null,
    methods: {

        getWssFailCounter() {
            return wss.failConnectCounter
        }

    },
    mounted() {

        this.themeChangeListener = (event) => {
            const elementId = 'theme-link';
            const linkElement = document.getElementById(elementId);
            const cloneLinkElement: any = linkElement.cloneNode(true);
            const newThemeUrl = linkElement.getAttribute('href').replace(this.$appState.theme, event.theme);

            cloneLinkElement.setAttribute('id', elementId + '-clone');
            cloneLinkElement.setAttribute('href', newThemeUrl);
            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', elementId);
            });
            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            this.$appState.theme = event.theme;
            this.$appState.darkTheme = event.dark;
        };

        this.changeTheme = (theme) => {

            localStorage.setItem('theme', theme);

            switch (theme) {

                case 'dark':
                    AppEventBus.emit('theme-change', {theme: 'vela-blue', dark: true});
                    break;

                case 'light':
                    AppEventBus.emit('theme-change', {theme: 'mdc-light-indigo-no-outline', dark: false});
                    break;


            }

        }

        AppEventBus.on('theme-change', this.themeChangeListener);
        AppEventBus.on('theme-change-preset', this.changeTheme);

        const theme = localStorage.getItem('theme');
        if (theme)
            this.changeTheme(theme);

        // Определение системной темы
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            // dark mode
            console.log('dark mode');
        } else {
            // light mode
            console.log('light mode');
        }

    },
    beforeUnmount() {
        AppEventBus.off('theme-change', this.themeChangeListener);
    },
    components: {
        BlockNotify,
        Notifies,
        ProgressBar,
    },
    data() {
        return {
            someHeight: null,
        }
    },


    /*    created() {

            const documentHeight = () => {
                const doc = document.documentElement
                doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
            }
            window.addEventListener('resize', documentHeight)
            documentHeight()

        }*/
}
