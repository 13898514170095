<template>
    <div class="layout-topbar">
        <router-link class="layout-topbar-logo" style="justify-content: center;" to="/">
            <!--			<img alt="Logo" :src="topbarImage()" />-->
            <span>Узел сопряжения ГосЭДО</span>
        </router-link>
        <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
            <i class="pi pi-bars"></i>
        </button>


        <button v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}"
                class="p-link layout-topbar-menu-button layout-topbar-button">
            <i class="pi pi-ellipsis-v"></i>
        </button>
        <ul class="layout-topbar-menu hidden lg:flex origin-top">
            <!--			<li>
                            <button class="p-link layout-topbar-button">
                                <i class="pi pi-calendar"></i>
                                <span>Events</span>
                            </button>
                        </li>
                        <li>
                            <button class="p-link layout-topbar-button">
                                <i class="pi pi-cog"></i>
                                <span>Settings</span>
                            </button>
                        </li>-->
            <li>

            </li>
        </ul>
    </div>
</template>

<script>

export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
        onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
        topbarImage() {
            //return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
            return this.$appState.darkTheme ? 'images/logo.png' : 'images/logo.png';
        }
    },
    computed: {
        darkTheme() {
            return this.$appState.darkTheme;
        }
    }
}
</script>