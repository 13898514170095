export default class MenuService {

    async getMenuItems() {

        return [
            {
                "label": "",
                "items": [
                    {
                        "label": "home",
                        "icon": "fas fa-link",
                        "to": "/home"
                    },
                ]
            }
        ];

    }

}