import {createRouter, createWebHashHistory} from 'vue-router';
import App from './App.vue';
import store from "./store";
import {AppEventBus} from "@xnpmpackages/xcomponents";

const routes: any = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/',
                name: 'def',
                redirect: '/chat-list',

            },
            {
                path: '/chat-list',
                name: 'chat-list',
                component: () => import('@/pages/ChatPageWrapper.vue'),

            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import('@/pages/SettingsPage.vue')

            },
        ]
    },

    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


router.beforeEach(async (to, from, next) => {

    if (to.matched[0]?.name !== 'login') {

        if (!store.state?.userInfo) {
            await next('/login');
        } else {
            await next()
        }

    } else {
        await next()
    }

});


AppEventBus.on('changeRoute', async url => {
    await router.push(url);
});

export default router;
