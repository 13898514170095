<template>
    <div class="layout-menu-container">
        <AppSubmenu :items="model" :root="true" class="layout-menu" @keydown="onKeyDown"
                    @menuitem-click="onMenuItemClick"/>
    </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
    props: {
        model: Array
    },
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onKeyDown(event) {
            const nodeElement = event.target;
            if (event.code === 'Enter' || event.code === 'Space') {
                nodeElement.click();
                event.preventDefault();
            }
        }
    },
    computed: {
        darkTheme() {
            return this.$appState.darkTheme;
        }
    },
    components: {
        'AppSubmenu': AppSubmenu
    }
}
</script>