import store from "@/store";
import FileStreamer from "@/service/FileStreamer";
import {DataFilters, Helper} from "@xnpmpackages/xcomponents/dist/index";
import {v4} from "uuid";
import router from "@/router";

class WSocket {

    public socket = null
    public promise = null
    public appIsActive = true
    public failConnectCounter = 0
    protected onMessageReceiveCallBacks = []
    protected server_url
    protected requests = {}
    protected reconnectIntervalIdentifier = null

    protected socketReady = false

    constructor(server_url) {
        this.server_url = server_url;
    }

    send = async (action: String, data: any) => {

        if (!this.socket)
            return;

        await this.promise;

        this.socket.send(JSON.stringify({
            action: action,
            data: data,
        }))

    }

    request = (action: string, data?: any, timeout: number = 5000): Promise<any> => {

        return new Promise((resolve, reject): any => {

            if (!this.socket) {
                reject(new Error('Отсутствует подключение к серверу'));
                return;
            }

            const request_id = v4()

            const request = {
                action: action,
                req_id: request_id,
                data: data,
            }

            this.socket.send(JSON.stringify(request))

            this.requests[request_id] = {
                timer: setTimeout(() => {
                    delete this.requests[request_id]
                    console.error({
                        'Невыполненный запрос': request
                    })
                    reject(new Error('Нет ответа на запрос за отведенное время: ' + DataFilters.methods.secondsToDateTime(timeout / 1000)))
                }, timeout),
                resolver: resolve
            }

        })

    }

    sendBlob = async (data: Blob) => {

        if (!this.socket)
            return;

        await this.promise;

        const stream = new FileStreamer(data)

        while (!stream.isEndOfFile()) {

            await this.waitForBuffer()
            const chunk = await stream.readNextChunk()

            this.socket.send(chunk)

        }

    }

    addOnMessageReceiveCallBack = (action, callback) => {
        this.onMessageReceiveCallBacks.push({action: action, callback: callback})
    }

    waitConnect = () => {
        return new Promise((resolve, reject) => {

            if (this.socketReady) {
                resolve(null);
            }

            const int = setInterval(() => {

                if (this.socketReady) {
                    resolve(null);
                    clearInterval(int);
                }

            }, 10)

        })
    }

    connect = () => {

        if (this.reconnectIntervalIdentifier)
            clearInterval(this.reconnectIntervalIdentifier)

        this.reconnectCycle()

        this.reconnectIntervalIdentifier = setInterval(this.reconnectCycle, 1000)

    }

    disconnect = () => {

        if (this.reconnectIntervalIdentifier)
            clearInterval(this.reconnectIntervalIdentifier)

        try {
            if (this.socket)
                this.socket.close()
        } catch (e) {
            console.error(e)
        }

    }

    reconnectCycle = () => {

        if (wss.socket || !router || router.currentRoute.value.name === 'login')
            return;

        this.socket = new window.WebSocket(this.server_url)

        this.socket.onopen = async () => {

            console.log("Соединение установлено");
            this.failConnectCounter = 0

            console.log("Аутентификация");
            const authResult = await this.request('auth', {sess_id: window.document.cookie.match(/PHPSESSID=[^;]+/)?.[0]})
            console.log(authResult);
            if (authResult?.result === 'success')
                console.log("Аутентификация пройдена!");

            if (store.state.auth_token)
                wss.request('sendPushSubscription', {auth_token: store.state.auth_token})

            this.request('getChatList').then(response => {
                if (response?.chat_list)
                    store.commit('setChatList', response.chat_list)
            })
            this.request('getUsers').then(response => {
                if (response?.data?.users)
                    store.commit('setUsers', response.data.users)
            })

            this.socketReady = true

        };

        this.socket.onclose = (event) => {

            this.socketReady = false

            if (event.wasClean) {
                console.log('Соединение закрыто чисто');
            } else {
                console.log('Обрыв соединения'); // например, "убит" процесс сервера
                this.failConnectCounter++
            }
            console.log('Код: ' + event.code + ' причина: ' + event.reason);
            this.socket = null
        };

        this.socket.onmessage = (event) => {

            console.log({"Получены данные": event.data});

            const data = JSON.parse(event.data)

            // Promise resolve on response if it was request
            if (data?.req_id && this.requests[data?.req_id]) {
                clearTimeout(this.requests[data?.req_id].timer);
                this.requests[data?.req_id].resolver(data?.data)
                delete this.requests[data?.req_id]
                return;
            }

            // Вызов колбэков у всех подписантов на события
            for (const subscription of this.onMessageReceiveCallBacks) {

                if (subscription.action === '*' || subscription.action === data.action) {
                    subscription.callback(data)
                }

            }

        };

        this.socket.onerror = function (event: any) {
            console.log("Ошибка сокета ", event);
        };

    }

    private waitForBuffer = async () => {

        while (this.socket.bufferedAmount && this.socket.readyState === 1) {
            await Helper.methods.sleep(5)
        }

        if (this.socket.readyState === 1) {
            return;
        } else {
            throw new Error('connection lost');
        }

    }

}

const wss = new WSocket(process.env.VUE_APP_WSS_URL);
wss.connect();

wss.addOnMessageReceiveCallBack('sendChatMessageToClient', async (message) => {

    // Посылка подтверждения получения сообщения
    wss.send('chatMessageAck', {
        message_id: message.data?.id
    })

    store.commit('removeUserTyping', {
        chat_id: message?.data?.chat_id,
        user_id: message?.data?.owner,
    })

})

wss.addOnMessageReceiveCallBack('updateLastReadMessageOnClient', async (message) => {


    const new_chat_list = Helper.methods.copyObjectByJSON(store.state.chatList).map((chat: any) => {

        const new_chat_item = Helper.methods.copyObjectByJSON(chat)

        if (message?.data?.chat_id === chat.id) {
            new_chat_item.last_reads = {
                ...chat.last_reads,
                [message?.data.user_id_which_read_message]: {

                    chat_id: message.data.chat_id,
                    user_id: message.data.user_id_which_read_message,
                    message_id: message.data.message_id,

                }

            }
        }

        return new_chat_item;

    })

    store.commit('setChatList', new_chat_list)


})

wss.addOnMessageReceiveCallBack('sendUserTypingToClients', (message) => {
    store.commit('addUserTyping', message?.data)
})


export default wss